.dashboard-performance-root {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 10px;
    padding-right: 20px;
    background: #F6F7F9;
}

.global-performance-title {
    display: flex;
    justify-content: flex-start;
    padding: 0px 30px 0px 35px;
}
.global-performance-title h1 {
    font-weight: 400;
    font-size: 130%;
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Helvetica Neue";
}

.global-performance-stats {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 10px 10px 10px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    gap: 10px;
    width: 90%;
}

.global-performance-stat {
    display: flex;
    flex: 1;
    height: 90px;
    border: 2px solid #ECEEF3;
    background: white;
    border-radius: 20px;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.dashboard-performance-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    padding: 0px 5px 0px 5px;
}
.global-performance-information {
    align-self: flex-start;
    font-family: "Helvetica Neue";
    margin: 0px;
    font-size: 70%;
    font-weight: 500;
    color: #ECEEF3;
    border: 2px solid #ECEEF3;
    padding: 0px 7px 0px 7px;
    border-radius: 100%;
    cursor: default;
}

.dashboard-performance-header-text {
    display: flex;
    align-items: center;
    flex-direction: center;
    gap: 10px;
    flex-direction: row;
}
.dashboard-performance-header img {
    align-self: center;
    justify-self: flex-start;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background: #F6F7F9;
    border: 1px solid #ECEEF3;
    padding: 2px 0px 0px 0px;
}
.dashboard-performance-text {
    margin: 0px;
    font-family: "Helvetica Neue";
    font-weight: 500;
}

.dashboard-stat-value {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    color: #0412F5;
}

.dashboard-ibis-certified-link {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;
    gap: 2px;
    color: #095c67b6;
}

.dashboard-ibis-certified-link p {
    font-size: 60%;
    margin: 0px;
    cursor: pointer;
}

.dashboard-ibis-certified-link svg {
    font-size: 50%;
    cursor: pointer;
    margin-bottom: 4px;
}

.dashboard-ibis-certified-link p:hover {
    text-decoration: underline;
}