.analytics-no-data {
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.analytics-no-data button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    gap: 10px;
    color: #0a7c8bb6;
    margin: 0px 10px 10px 10px;
    border-radius: 5px;
    height: 40px;
    width: 300px;
    cursor: pointer;
    border: 1px solid #0a7c8bb6;
    background-color: rgb(243, 242, 242);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.analytics-no-data button:hover {
    border: 2px solid #0a7c8bb6;
    text-decoration: underline;
}
