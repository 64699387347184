.App {
  text-align: center;
  background-color: white;
  width: 100%;
  overflow: hidden;
}

.app-components {
  display: flex;
  width: 100%;
  /* height: 100vh; */
  overflow-y: hidden;
  flex-direction: row;
}

.authorised-components {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.login-page {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.login-page-logo {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  color: white;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  background: #0a7c8bb6;
}

.login-page-authenticator {
  width: 50%;
}

@media (max-width: 768px) {
  .app-components {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .login-page {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  
  .login-page-logo {
    display: none;
  }
  
  .login-page-authenticator {
    width: 100%;
  }
}
