.feedback-root {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
}

.feedback {
    display: flex;
    margin: 0px 0px 0px 40px;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    border-radius: 10px;
    flex-direction: column;
}

.feedback-question-navigation {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 30px 0px 30px;
}

.feedback-question-navigation-button {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    color: #0a7c8bb6;
    margin: 10px;
    border-radius: 5px;
    width: 200px;
    height: 35px;
    cursor: pointer;
    border: 1px solid #0a7c8bb6;
    background-color: rgb(243, 242, 242);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.feedback-question-navigation-button-disabled {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    color: gray;
    margin: 10px;
    border-radius: 5px;
    width: 200px;
    height: 35px;
    border: 1px solid rgb(243, 242, 242);
    background-color: rgb(243, 242, 242);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.feedback-question-navigation-button:hover {
    border: 2px solid #0a7c8bb6;
    text-decoration: underline;
}

.question {
    display: flex;
    width: 100%;
    justify-content: center;
}
.question-div {
    display: flex;
    width: 90%;
    padding: 10px;
    gap: 15px;
}

.question-text {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    width: 90%;
    text-align: start;
    flex: 1;
    font-size: 100%;
}
.question-title {
    display: flex;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 600;
    text-decoration: underline;
    font-size: 100%;
}

.answer-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.correct-answer-border {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 100%;
    width: 90%;
    border: 1px solid green;
    flex-direction: column;
    border-radius: 10px;
    text-align: start;
    padding: 10px;
    margin: 5px;
}

.correct-label {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 100%;
    font-weight: 500;
    border-radius: 5px;
    margin: 0px;
    background-color: green;
    color: white;
    font-size: 80%;
    padding: 2px 10px 2px 10px;
}

.incorrect-label {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 100%;
    font-weight: 500;
    border-radius: 5px;
    margin: 0px;
    background-color: red;
    color: white;
    font-size: 80%;
    padding: 2px 10px 2px 10px;
}

.incorrect-answer-border {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 100%;
    width: 90%;
    border: 1px solid red;
    border-radius: 10px;
    text-align: start;
    padding: 10px;
    margin: 5px;
}

.non-special-answer {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 100%;
    width: 90%;
    border-radius: 10px;
    text-align: start;
    padding: 10px;
    margin: 5px;
}

.answer-label {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
}

.explanation {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.explanation-div {
    display: flex;
    width: 90%;
    padding: 10px;
    gap: 15px;
}

.explanation-title {
    display: flex;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 600;
    text-decoration: underline;
    font-size: 100%;
}

.explanation-text {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 100%;
    flex: 1;
    text-align: start;
}