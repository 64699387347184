.leaderboard-root {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.leaderboard-top {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px 0px 20px 40px
}

.leaderboard-body {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 0px 40px 0px 40px;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.leaderboard-header {
    display: flex;
    flex-direction: row;
    background-color: #0a7c8bb6;
    border-bottom: 1px solid rgb(186, 185, 185);
    padding: 20px 0px 20px 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.leaderboard-header p {
    display: flex;
    justify-content: center;
    flex: 1;
    font-weight: 700;
    color: white;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0px;
    font-size: 60%;
    text-align: start;
}

.leaderboard-entries {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 300px;
    border: 1px solid rgb(186, 185, 185);
}

.leaderboard-result-grey {
    background: rgb(244, 244, 244);
    border-top: 1px solid rgb(186, 185, 185);
    display: flex;
    flex-direction: row;
    padding: 2px 0px 2px 0px;
}

.leaderboard-result-white {
    background: white;
    border-top: 1px solid rgb(186, 185, 185);
    display: flex;
    flex-direction: row;
    padding: 2px 0px 2px 0px;
}

.leaderboard-result-top, 
.leaderboard-result-bottom {
    display: flex;
    padding: 2px 0px 2px 0px;
    position: sticky;
    flex-direction: row;
    z-index: 2;
    background: gold;
}

.leaderboard-result-top {
    top: 0;
}
.leaderboard-result-bottom {
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.leaderboard-result-top p {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 80%;
    flex: 1;
}
.leaderboard-result-bottom p {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 80%;
    flex: 1;
}

.leaderboard-result-white p {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 80%;
    flex: 1;
}

.leaderboard-result-grey p {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 80%;
    flex: 1;
}

.leaderboard-result-bottom {
    background: white;
    border-top: 1px solid rgb(186, 185, 185);
    display: flex;
    flex-direction: row;
    padding: 2px 0px 2px 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.loading-more-users {
    border-top: 1px solid rgb(186, 185, 185);
    padding: 15px 0px 15px 0px;
}
