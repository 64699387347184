.home-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.white-background {
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
}

.home-components {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #F6F7F9;
  width: 100%;
}

.home-components-scrollable {
  display: flex;
  align-items: center;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

@media (max-width: 768px) {
  .home-container {
    display: flex;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: 40px;
    overflow-y: auto;
  }

  .home-components {
    flex-grow: 1;
    overflow-y: visible;
    padding: 0px;
    padding-bottom: 20px;
  }
}
