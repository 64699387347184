.side-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 300px;
  border-radius: 0px;
  text-decoration: none;
  border-right: 2px solid #ECEEF3;
}

.side-bar-navigation {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: white;
  border-bottom: 1px solid #ECEEF3;
}

.logo-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  align-items: center;
  border-bottom: 2px solid #ECEEF3;
  height: 60px;
}

.logo-div svg {
  flex: 1;
  height: 20px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.logo-div h1 {
  flex: 1;
  font-family: "Helvetica Neue";
  font-weight: 500;
  margin: 12px 0px 12px 0px;
  font-size: 130%;
  display: flex;
  color: #0412F5;
}

.side-bar-nav-title {
  padding-top: 20px;
  padding-left: 30px;
  font-family: "Helvetica Neue";
  margin: 0px;
  font-size: 60%;
  color: #5E5E5E;
  text-align: start;
}
.side-bar-nav-title-minimised {
  display: flex;
  padding-top: 20px;
  width: 100%;
  justify-content: center;
  font-family: "Helvetica Neue";
  margin: 0px;
  font-size: 60%;
  color: #5E5E5E;
  text-align: start;
}

.side-bar-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 10px 15px 10px;
}

.side-bar-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  padding-top: 0px;
}

.side-bar-nav li {
  display: flex;
  width: 100%;
  text-align: start;
}

.side-bar-nav a {
  color: rgb(97, 97, 97);
  font-size: 80%;
  display: block;
  width: 100%;
  margin: 0px 0px 0px 0px;
  background: transparent;
  text-decoration: none;
  padding: 4px;
}

.side-bar-nav a.active {
  color: black;
  font-weight: 500;
  background: #F6F7F9;
  border-radius: 10px;
}
.side-bar-nav a.active-minimised {
  color: black;
  font-weight: 500;
  background: #F6F7F9;
  border-radius: 10px;
  margin: 5px;
}

.nav-label-active {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-size: 110%;
  margin: 0px;
  color: black;
  padding: 2px 2px 2px 10px;
  background: #F6F7F9;
  border-radius: 5px;
}
.nav-label-active-minimised {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-size: 110%;
  margin: 0px;
  color: black;
  padding: 2px 2px 2px 0px;
  background: #F6F7F9;
  border-radius: 5px;
}

.nav-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 110%;
  padding: 2px 2px 2px 10px;
  font-family: "Helvetica Neue";
  font-weight: 400;
  margin: 0px;
  color: black;
}
.nav-label-minimised {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 110%;
  padding: 2px 0px 2px 0px;
  font-family: "Helvetica Neue";
  font-weight: 400;
  margin: 0px;
  color: black;
}

.nav-label-icon {
  align-self: center;
  justify-self: flex-start;
  width: 25px;
  height: 25px;
  background: transparent;
  border: transparent;
  padding: 2px 0px 0px 0px;
}
.nav-label-icon-minimized {
  align-self: center;
  width: 35px;
  height: 35px;
}

.nav-label:hover {
  background-color: white;
  border-radius: 5px;
  font-weight: 400;
}

.logout-div {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logout-div button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 5px;
  color: rgb(55, 55, 55);
  font-family: "Helvetica Neue";
  font-size: 80%;
  font-weight: 300;
  border: 1px solid rgb(186, 185, 185);
  width: 90%;
  margin-bottom: 10px;
  padding: 10px;
}

.logout-div button:hover {
  font-weight: 400;
  cursor: pointer;
  border: 2px solid rgb(186, 185, 185);
  text-decoration: underline;
}

.logout-div button:hover .logout {
  color: black;
  font-size: 15px;
}

.side-bar-mobile {
  display: none;
}

.side-bar-bottom-logo {
  display: flex;
  margin-top: auto;
  width: 100%;
  justify-content: center;
  border-top: 1px solid #ECEEF3;
  font-family: "Helvetica Neue";
  color: #5E5E5E;
  font-size: 80%;
}

@media (max-width: 768px) {}