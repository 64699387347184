.graphs-root {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
}

.graph-label {
    font-size: 60%;
    font-weight: 400;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: grey;
}

.section-history-graph {
    width: 365px;
    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 10px 10px 0px 10px;
    border-radius: 10px;
}

.average-history-graph {
    width: 365px;
    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 10px;
    border-radius: 10px;
}
.dashboard-text-h3-styling {
    justify-self: flex-start;
}
.average-history-graph div {
    justify-self: flex-end;
}
.time-graph {
    width: 365px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 10px;
    border-radius: 10px;
}
/* .time-graph div {
    border-radius: 10px;
    border: 1px solid black;
}
.time-graph div svg {
    padding: 10px;
} */
.score-graph {
    width: 420px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 10px;
    border-radius: 10px;
}
.section-graph {
    width: 700px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
}
