.heading-root {
    display: flex;
    width: 95%;
    align-items: flex-start;
    padding: 15px 0px 10px 0px;
    border-bottom: 3px solid #ECEEF3;
    background: #F6F7F9;
}

.dashboard-heading {
    font-family: "Helvetica Neue";
    margin: 0px;
    font-size: 130%;
    font-weight: 500;
}