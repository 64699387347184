.contact {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 50px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.contact h1 {
  font-size: 30px;
  padding-bottom: 10px;
  margin: 0px;
}

.contact p {
  margin: 0px;
  padding: 5px;
}

@media (max-width: 768px) {
  .contact {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 25px;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .contact h1 {
    font-size: 20px;
    padding-bottom: 10px;
    margin: 0px;
  }

  .contact p {
    margin: 0px;
    padding: 2px;
    font-size: 10px;
  }
}
