.score-barchart {
  width: 400px;
  height: 250px;
  position: relative;
}

@media (max-width: 768px) {
  .score-barchart {
    width: 300px;
    height: 200px;
    position: relative;
  }
}
