.section-barchart {
  width: 700px;
  height: 200px;
  position: relative;
}

@media (max-width: 768px) {
  .section-barchart {
    width: 300px;
    height: 300px;
    position: relative;
  }
}
