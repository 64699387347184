.enter-username-root {
    display: flex;
    padding: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.enter-username-root h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 200%;
    color: rgb(91, 88, 88);
    margin-top: 5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 0px;
    width: 70%;
}

.enter-username-information p {
    color: #036976b6;
    text-decoration: underline;
    margin: 0px;
}

.enter-username-div {
    display: flex;
    margin-top: 50px;
    gap: 20px;
}

.enter-username-div input {
    width: 250px;
    border-radius: 5px;
    border: 1px solid rgb(160, 159, 159);
    padding-left: 10px;
}

.enter-username-div input:hover {
    border: 2px solid #0a7c8bb6;
}

.enter-username-div button {
    border-radius: 5px;
    background: #0a7c8bb6;
    border-color: #0a7c8bb6;
    font-size: 100%;
    font-weight: bold;
    color: white;
    height: 40px;
    width: 200px;
    cursor: pointer;
}

.enter-username-div button:hover {
    background: white;
    border-color: #0a7c8bb6;
    color: #0a7c8bb6;
}

.invalid-email-message {
    color: red;
    text-decoration: underline;
    margin-top: 0px;
    font-size: 80%;
    text-align: start;
    margin-top: 5px;
}

@media (max-width: 768px) {
    .enter-username-root h1 {
        font-size: 120%;
        width: 100%;
    }

    .enter-username-information p {
        font-size: 60%;
    }

    .enter-username-div input {
        width: 150px;
        font-size: 60%;
    }

    .enter-username-div button {
        width: 100px;
        font-size: 60%;
        height: 30px;
    }
}