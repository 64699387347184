.profile-header-root {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 90%;
    margin-left: 40px;
    padding: 10px 0px 20px 0px;
    border-bottom: 1px solid rgb(186, 185, 185);
}

.profile-header-root svg {
    font-size: 350%;
}

.profile-username {
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin: 0px 0px 0px 15px;
}

.profile-navigation {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.profile-navigation button {
    cursor: pointer;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    color: #0a7c8bb6;
    margin: 10px;
    border-radius: 5px;
    height: 30px;
    width: 100px;
    cursor: pointer;
    border: 1px solid #0a7c8bb6;
    background-color: rgb(243, 242, 242);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.profile-navigation button:hover {
    border: 2px solid #0a7c8bb6;
    text-decoration: underline;
}