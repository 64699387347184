.bookatest-root {
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  border-radius: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.bookatest.blur-background {
  filter: blur(5px); /* Blurs the background when StripePayment is active */
  pointer-events: none; /* Makes the background non-interactive */
}

.bookatest {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.bookatest-components {
  display: flex;
  flex-direction: column;
  padding: 5px;
  align-items: center;
}

.stripe-payment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 100;
}

.stripe-payment-overlay > * {
  z-index: 101;
}

@media (max-width: 768px) {
  .bookatest-root {
    display: flex;
    height: calc(100vh - 40px);
    margin-top: 40px;
    width: 100%;
    flex-direction: column;
    overflow-y: auto;
    padding: 5px;
  }

  .bookatest {
    display: flex;
    position: relative;
    width: 100%;
    flex-grow: 1;
    overflow-y: visible;
    margin-bottom: 20px;
  }

  .bookatest-components {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 5px;
    align-items: center;
  }
}