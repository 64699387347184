.why-take-ibis {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex-direction: column;
  padding-bottom: 3%;
}

.why-take-ibis h1 {
  color: rgb(91, 88, 88);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-size: 300%;
  font-weight: bold;
}

.information {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.reasons {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
  padding-left: 20%;
  gap: 10px;
}

.reason-heading {
  display: flex;
  flex-direction: row;
}

.reason-number h3 {
  color: #0a7c8bb6;
  padding-right: 10px;
  font-weight: 1000;
  font-size: 190%;
  margin: 0px;
}

.reason-text {
  line-height: 2;
  width: 70%;
  text-align: start;
}

.reason-text p {
  font-size: 100%;
  margin-top: 0px;
  margin-bottom: 20px;
}

.reason h3 {
  font-weight: 50;
  font-size: 190%;
  margin: 0px;
  color: rgb(91, 88, 88);
}

.graphic {
  display: flex;
  padding: 0;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
}

.graphic img{
  width: 90%;
}

.button-take-test {
  border: none;
  margin-top: 20px;
  margin-left: 20px;
  border-radius: 5px;
  background-color: #0a7c8bb6;
  color: white;
  width: 150px; /* Adjust width to fit text */
  height: 50px;
  font-weight: bold;
  cursor: pointer;
}

.button-take-test:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .why-take-ibis {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    flex-direction: column;
    padding-bottom: 5%;
  }
  
  .why-take-ibis h1 {
    color: rgb(91, 88, 88);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    font-size: 150%;
    font-weight: bold;
  }

  .information {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .reasons {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding-left: 10%;
    gap: 10px;
  }
  
  .reason-heading {
    display: flex;
    flex-direction: row;
  }
  
  .reason-number h3 {
    color: #0a7c8bb6;
    padding-right: 10px;
    font-weight: 1000;
    font-size: 100%;
    margin: 0px;
  }

  .reason h3 {
    font-weight: 50;
    font-size: 100%;
    margin: 0px;
  }
  
  .reason-text {
    line-height: 1.5;
    width: 70%;
    text-align: start;
  }
  
  .reason-text p {
    font-size: 60%;
    margin-top: 0px;
  }
  
  .graphic {
    display: flex;
    padding: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .graphic {
   display: none;
  }
  
  .button-take-test {
    border: none;
    margin-top: 0px;
    margin-left: 0px;
    border-radius: 2px;
    background-color: #0a7c8bb6;
    color: white;
    width: 70px;
    height: 25px;
    font-size: 8px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .button-take-test:hover {
    background-color: white;
    color: black;
  }
}

