.leaderboard-container {
  background-color: transparent;
  overflow: hidden;
  width: 70%;
  border: 2px solid #0a7c8bb6;
  box-shadow: 5px 5px 10px rgb(91, 88, 88);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

#leaderboardFilter {
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
  border: 1px solid black;
  align-items: center;
  height: 5%;
  margin: 10px;
}

.leaderboard-filter-div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.leaderboard-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.leaderboard-filters p {
  font-family: 'Roboto', sans-serif;
  color: white;
}

.leaderboard-filter-div p {
  font-family: 'Roboto', sans-serif;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  margin: 0px;
  font-size: 20px;
}

.results-selected-test {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
}

.results-selected-test h2 {
  font-family: 'Roboto', sans-serif;
  margin: 0px 0px 0px 0px;
  font-size: 200%;
  width: 300px;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.results-selected-test-btn-scroll {
  background-color: transparent;
  border: transparent;
  font-size: 250%;
  color: white;
  cursor: pointer;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.results-selected-test-btn-scroll:hover {
  color: rgb(197, 245, 252);
}

.inactive-hide-btn-scroll {
  color: #0a7c8bb6;
  border: transparent;
  background-color: transparent;
  font-size: 250%;
  text-shadow: none;
}

.leaderboard-header {
  display: flex;
  background-color: transparent;
  backdrop-filter: blur(10px);
  padding: 20px;
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  flex-direction: column;
}

.leaderboard-table {
  width: 100%;
  margin-top: 5px;
  border-collapse: collapse;
  background: white;
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.leaderboard-table th {
  background-color: #f5f5f5;
}

.leaderboard-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.leaderboard-rank {
  font-weight: bold;
  color: #0073e6;
}

.leaderboard-table tbody {
  display: block;
  height: 250px;
  overflow-y: auto;
}

.leaderboard-table thead,
.leaderboard-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 25px;
}

.leaderboard-table thead {
  width: 100%;
}

.user-has-no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.user-has-no-results p {
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  width: 90%;
  color: rgb(161, 19, 19);
}

.user-has-no-results button {
  background-color: #0a7c8bb6;
  border-color: #0a7c8bb6;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  width: 150px;
  height: 35px;
  font-size: 100%;
  cursor: pointer;
}

.user-has-no-results button:hover {
  background-color: white;
  border-color: #0a7c8bb6;
  color: #0a7c8bb6;
}

@media (max-width: 768px) {
  .leaderboard-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 5px;
    overflow: hidden;
    min-width: 90%;
    border: 1px solid black;
    box-shadow: 2px 2px 5px #0a7c8bb6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .results-selected-test {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin: 5px;
    align-items: center;
  }

  .results-selected-test h2 {
    margin: 0px 0px 0px 0px;
    font-size: 70%;
    width: 100%;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .results-selected-test-btn-scroll {
    background-color: transparent;
    border: transparent;
    font-size: 100%;
    color: white;
    cursor: pointer;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .inactive-hide-btn-scroll {
    color: #0a7c8bb6;
    border: transparent;
    background-color: transparent;
    font-size: 100%;
    text-shadow: none;
  }

  #leaderboardFilter {
    border-radius: 5px;
    padding: 5px;
    font-size: 30%;
    size: 10px;
    border: 1px solid black;
    align-items: center;
    height: 1%;
    margin: 10px;
  }

  .leaderboard-filter-div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }

  .leaderboard-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
  }

  .leaderboard-filters p {
    color: white;
    font-size: 50%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .leaderboard-header {
    display: flex;
    background-color: transparent;
    backdrop-filter: blur(10px);
    text-align: center;
    padding: 0px;
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    flex-direction: column;
  }

  .leaderboard-table {
    width: 100%;
    margin-top: 5px;
    border-collapse: collapse;
    background: white;
  }

  .leaderboard-table th,
  .leaderboard-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #eee;
    font-size: 10px;
  }

  .leaderboard-table th {
    background-color: #f5f5f5;
  }

  .leaderboard-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .leaderboard-rank {
    font-weight: bold;
    color: #0073e6;
  }

  .leaderboard-table tbody {
    display: block;
    height: 200px;
    overflow-y: auto;
  }

  .leaderboard-table thead,
  .leaderboard-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 25px;
  }

  .leaderboard-table thead {
    width: 100%;
  }

  .user-has-no-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .user-has-no-results p {
    font-size: 70%;
    width: 90%;
    color: rgb(161, 19, 19);
  }
  
  .user-has-no-results button {
    background-color: #0a7c8bb6;
    border-color: #0a7c8bb6;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    width: 100px;
    height: 25px;
    font-size: 60%;
    cursor: pointer;
  }
}
