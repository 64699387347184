.stats {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20%;
  padding: 20px 0;
  padding-bottom: 2%;
  background-color: #0a7c8bb6;
}

.stats-div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 200px;
}

.stats .stat {
  max-width: 20%;
  text-align: center;
}

.stats .stat:first-child {
  border-left: none;
}

.stats .stat h2 {
  margin: 0;
  font-size: 400%;
  color: white;
  font-weight: bold;
}

.stats .stat p {
  font-weight: bold;
  font-size: 120%;
  margin: 10px 0 0;
  color: white;
}


@media (max-width: 768px) {
  .stats {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #0a7c8bb6;
    padding-bottom: 20px;
  }

  .stats-div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  
  .stats .stat {
    display: flex;
    flex-direction: column;
    max-width: 20%;
    margin: 0px;
  }
  
  .stats .stat h2 {
    margin: 0;
    font-size: 100%;
    color: white;
    font-weight: bold;
  }
  
  .stats .stat p {
    font-weight: bold;
    font-size: 50%;
    margin: 0px;
    color: white;
  }
}