.profile-edit-root {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 20px 10px;
    margin-left: 40px;
    width: 90%;
    border-bottom: 1px solid rgb(186, 185, 185);
}

.profile-edit {
    display: flex;
    flex-direction: row;
    width: 90%;
}

.profile-set {
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: row;
}

.profile-titles {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    flex-direction: column;
}

.profile-titles p {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 500;
    text-align: start;
    width: 150px;
    margin: 10px 0px 10px 0px;
}

.profile-values {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.profile-values p {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: rgb(86, 86, 86);
    text-align: start;
    margin: 10px 0px 10px 0px;
}

.profile-values input {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 8px 0px 8px 0px;
    border: 1px solid rgb(186, 185, 185);
    padding-left: 10px;
    border-radius: 5px;
}

.profile-values div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5px 0px 10px 0px;
}

.profile-values div select {
    height: 30px;
    font-size: 80%;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    border: 1px solid rgb(186, 185, 185);
    border-radius: 5px;
    padding-left: 10px;
}

.profile-values div input {
    height: 30px;
    margin: 0px;
    font-size: 80%;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    border-bottom: 1px solid rgb(186, 185, 185);
    border-radius: 5px;
    padding-left: 10px;
}

.save-edit {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-end;
}

.save-edit button {
    cursor: pointer;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    color: #0a7c8bb6;
    margin: 20px 10px 10px 0px;
    border-radius: 5px;
    height: 30px;
    width: 100px;
    cursor: pointer;
    border: 1px solid #0a7c8bb6;
    background-color: rgb(243, 242, 242);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.edit-mode-phone-number {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
}

.save-edit button:hover {
    border: 2px solid #0a7c8bb6;
    text-decoration: underline;
}

.gender-radio-label {
    margin-top: 0px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.invalid-input-message {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: red;
    font-size: 100%;
    padding-right: 100px;
    text-align: center;
}

.consents-div {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    flex-direction: row;
}
