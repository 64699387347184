.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background-color: white;
  text-align: center;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease; /* Changed to transform for smoother effect */
  z-index: 1000; /* Ensure the header is always on top */
}

.header-hidden {
  transform: translateY(-100%); /* Move the header out of view */
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligns the nav and login button to the right */
}

.ibis-logo {
  color: black;
  margin: 2px;
  padding-left: 20px;
}

.header-content {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  margin-right: 20px;
}

nav ul li {
  padding: 0 20px;
  position: relative;
}

nav ul li a {
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav nav ul li a {
  font-size: 150%; /* Adjust font size */
  font-weight: 100;
  color: black;
}

nav ul li a:hover {
  color: #064249b6; /* Change color on hover */
}

.login {
  padding-right: 1%;
}

.login-button {
  border: none;
  cursor: pointer;
  background-color: #0a7c8bb6;
  color: #ffffff;
  width: 150px;
  height: 50px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px; /* Adjust font size */
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .header-content {
    display: flex;
    background-color: white;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: row;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;
    background-color: white;
    text-align: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; /* Changed to transform for smoother effect */
    z-index: 1000; /* Ensure the header is always on top */
  }

  .ibis-logo {
    font-size: 100%;
    padding: 0px;
    margin: 0px;
  }

  .header-hidden {
    transform: translateY(-100%); /* Move the header out of view */
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  nav ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
  }

  nav ul li {
    position: relative;
  }

  .nav nav ul li a {
    font-size: 60%; /* Adjust font size */
    font-weight: 100;
    color: black;
  }

  nav ul li a:hover {
    color: #025ec1; /* Change color on hover */
  }

  .login {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 20%;
  }

  .login-button {
    border: none;
    cursor: pointer;
    background-color: #0a7c8bb6;
    color: #ffffff;
    width: 70px;
    height: 25px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 8px; /* Adjust font size */
    transition: background-color 0.3s ease;
  }

  .login-button:hover {
    background-color: white;
    color: black;
  }
}
