.leader-board-info {
  padding-top: 3%;
}

.heading {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  margin: auto; /* Center horizontally */
  flex-direction: column; /* Stack children vertically */
  text-align: center; /* Center-align the text */
}

.leader-board-info-heading {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  text-align: center;
}

.leader-board-info-heading h1 {
  width: 70%;
  margin: 0;
  color: rgb(91, 88, 88);
  padding-bottom: 50px;
  font-size: 300%;
  font-weight: bold;
}

.leader-board-image {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.leader-board-image img {
  width: 40%;
}

.logos {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  margin: 70px 0;
}

.logos img {
  width: 50%;
}

@media (max-width: 768px) {
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto; 
    flex-direction: column;
    text-align: center;
  }

  .leader-board-info-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .leader-board-info-heading h1 {
    width: 80%;
    margin: 0;
    color: rgb(91, 88, 88);
    padding-bottom: 50px;
    font-size: 100%;
    font-weight: bold;
    padding: 0px;
  }

  .leader-board-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .leader-board-image img {
    width: 70%;
  }

  .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    padding-bottom: 20px;
  }

  .logos img {
    width: 80%;
  }
}
