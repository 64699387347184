.terms-and-conditions-root {
    display: flex;
    background: white;
    width: 100%;
    flex-direction: column;
    padding-bottom: 10px;
    padding-right: 20px;
}

.tandc-div {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(186, 185, 185);
    border-radius: 10px;
    height: 200px;
    margin-left: 40px;
    overflow-y: auto;
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 5px;
}

.tandc-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 5px 0px 5px 0px;
}

.tandc-header {
    font-size: 90%;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    margin: 0px 0px 0px 5px;
}

.tandc-information {
    font-size: 80%;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 300;
    margin: 2px 2px 2px 20px;
    text-align: start;
}

.tandc-checkbox-div {
    display: flex;
    align-items: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    font-size: 80%;
    display: flex;
    margin: 5px 0px 5px 40px;
    flex-direction: row;
}
.tandc-checkbox-star {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: red;
    font-size: 200%;
    margin: 0px;
}