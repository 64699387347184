.profile-non-edit-root {
    display: flex;
    flex-direction: row;
    width: 90%;
    padding: 20px 0px 20px 10px;
    margin-left: 40px;
    border-bottom: 1px solid rgb(186, 185, 185);
}

.profile-set {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.profile-titles {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.profile-titles p {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 500;
    text-align: start;
    width: 150px;
    margin: 5px 0px 5px 0px;
}

.profile-values {
    display: flex;
    flex-direction: column;
}
.profile-values p {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: rgb(86, 86, 86);
    text-align: start;
    margin: 5px 0px 5px 0px;
}