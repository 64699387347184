.dashboard-text-header-div-styling {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px 0px 20px 40px
}

.dashboard-text-h3-styling {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0px;
    font-size: 130%;
    font-weight: 400;
}

.dashboard-text-p-styling {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0px;
    font-size: 80%;
    color: grey;
    text-align: start;
}