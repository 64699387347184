.analytics-statistics-root {
    display: flex;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    border-radius: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
}

.analytics-statistics {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 0px 40px;
}