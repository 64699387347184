.results {
  display: flex;
  position: relative;
  height: 100vh;
}

.results-components {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 0px 5px 5px 5px;
}

.results-background {
  border-radius: 0px;
  display: flex;
  flex: 1;
  background: linear-gradient(to bottom, #0a7c8bb6 7%, white 40%);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding-bottom: 30px;
}

.results-title {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 50px;
  background: transparent;
  margin: 20px 0 0 0;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.test-results {
  background: transparent;
}

@media (max-width: 768px) {
  .results {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100); /* This uses dynamic --vh */
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding-top: 40px;
  }

  .results-components {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px;
    flex-grow: 1;
    overflow-y: auto;
  }

  .results-background {
    border-radius: 0px;
    display: flex;
    flex: 1;
    background: linear-gradient(to bottom, #0a7c8bb6 7%, white 40%);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    padding-bottom: 30px;
  }

  .results-title {
    color: white;
    font-size: 120%;
    width: 90%;
    background: transparent;
    margin: 20px 0 0 0;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  }

  .test-results {
    background: transparent;
  }
}
