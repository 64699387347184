.password-reset-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.password-reset-message h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 200%;
    color: rgb(91, 88, 88);
    margin: 30px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    width: 70%;
}

.password-reset-message button {
    border-radius: 5px;
    background: #0a7c8bb6;
    border-color: #0a7c8bb6;
    font-size: 100%;
    font-weight: bold;
    color: white;
    height: 40px;
    width: 200px;
    cursor: pointer;
}

.password-reset-message button:hover {
    background: white;
    border-color: #0a7c8bb6;
    color: #0a7c8bb6;
}

@media (max-width: 768px) {
    .password-reset-message h1 {
        font-size: 150%;
        margin: 20px;
    }

    .password-reset-message button {
        width: 100px;
        font-size: 60%;
        height: 30px;
    }
}