.line-chart {
    height: 200px;
    width: 330px;
    display: flex;
}

@media (max-width: 768px) {
    .line-chart {
        width: 300px;
        height: 300px;
        position: relative;
    }
}