.leaders-root {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.leaders {
    display: flex;
    flex-direction: row;
    padding-left: 40px;
    gap: 20px;
}

.leader {
    display: flex;
    flex-direction: column;
    width: 350px;
    border-radius: 10px;
    border: 1px solid #0a7c8bb6;
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.leader-header {
    display: flex;
    height: 100px;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 5px 5px 0px 5px;
}
.leader-icon {
    background: #0a7c8bb6;
    padding: 10px;
    color: white;
    border-radius: 30px;
    font-size: 50px;
    margin: 0px 20px 0px 10px;
}
.leader-header h3 {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    padding-right: 20px;
}

.leader-header-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.leader-stats {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #0a7c8bb6;
}

.leader-stat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 1px solid #0a7c8bb6;
    padding: 5px 0px 0px 10px;
    flex: 1;
}
.leader-stat h3 {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0px;
    font-size: 100%;
    font-weight: 400;
}