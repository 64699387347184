.custom-auth-container {
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa; /* Match your landing page background color */
}

.logo h1 {
  font-size: 250%;
  color: #0a7c8bb6;
  margin: 0px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.logo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.amplify-flex div {
  display: flex;
  align-items: center;
}

.amplify-alert--error {
  display: flex;
  padding: 10px;
}

.amplify-alert--error button {
  width: 10%;
  background: transparent;
  color: black;
  font-weight: bold;
}

.amplify-alert__icon {
  align-items: center;
  justify-content: center;
}

.custom-header {
  text-align: center;
  background-color: #0a7c8bb6; /* Match your header background color */
  padding: 20px;
  border-radius: 5px 5px 0 0;
  width: 100%;
}

.custom-header h2 {
  color: #fff; /* Match your header text color */
  margin: 0;
}

.custom-footer {
  text-align: center;
  padding: 10px;
  background-color: white;
  border-radius: 0 0 5px 5px;
  width: 100%;
  color: #0a7c8bb6;
}

.custom-footer a {
  color: #0a7c8bb6;
}

.amplify-input {
  border: 1px solid #0a7c8bb6; /* Match input border color to your branding */
  border-radius: 5px;
  padding: 10px; /* Adjusted padding; make sure it is balanced */
  height: 50px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left; /* Ensure text alignment is set to left */
}

.amplify-button {
  background-color: #0a7c8bb6; /* Match button background color to your branding */
  padding: 10px;
  color: #fff; /* Match button text color */
  width: 100%;
}

.amplify-field-group__outer-end .amplify-button {
  background-color: white;
  border-color: white;
}

.amplify-field-group__outer-end .amplify-button:active {
  background-color: white;
  border-color: white;
}

.amplify-field-group__outer-end .amplify-button .amplify-icon {
  color: black;
}

.amplify-tabs {
  background-color: #001f3f;
  color: white;
  text-align: center;
}

.amplify-input-container {
  position: relative;
  width: 100%;
  margin-bottom: 10px; /* Space between input fields */
}

.amplify-field-group__field-wrapper .amplify-input {
  width: 100%;
}

.amplify-input {
  width: 80%;
}

.amplify-input:focus {
  border-color: #0a7c8bb6;
}

.amplify-flex {
  align-items: flex-start;
  width: 100%;
}