.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-box {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
}

.tooltip-container:hover .tooltip-box {
    opacity: 1;
    visibility: visible;
}