.small-test-options-root {
    display: flex;
    border: 2px solid #ECEEF3;
    color: black;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    flex: 0 0 50%;
    padding: 10px 0px 10px 0px;
    background: white;
    border-radius: 20px;
    padding: 20px;
}

.small-test-option {
    border-radius: 20px;
    width: 300px;
    padding: 0px 20px 0px 20px;
}

.small-test-option-top {
    display: flex;
    width: 100%;
    border-bottom: 2px solid #ECEEF3;
    justify-content: space-between;
}
.small-test-option-id {
    margin: 10px 0px 10px 0px;
    font-family: "Helvetica Neue";
    font-size: 110%;
}
.small-test-performance-information {
    align-self: center;
    font-family: "Helvetica Neue";
    margin: 0px;
    font-size: 70%;
    font-weight: 500;
    color: #ECEEF3;
    border: 2px solid #ECEEF3;
    padding: 0px 7px 0px 7px;
    border-radius: 100%;
}

.small-test-option-middle {
    display: flex;
    padding: 20px 0px 20px 0px;
    flex-direction: column;
    gap: 10px;
    border-bottom: 2px solid #ECEEF3;
    width: 100%;
}

.small-test-option-point {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.small-test-option-point img {
    align-self: center;
    justify-self: flex-start;
    width: 25px;
    height: 25px;
    background: white;
    padding: 2px 0px 0px 0px;
}

.small-test-option-price {
    display: flex;
    justify-content: flex-start;
}
.small-test-price {
    font-family: "Helvetica Neue"; 
    font-weight: 500;
    font-size: 110%;
}

.completed-practice-test-label {
    font-family: "Helvetica Neue"; 
    font-weight: 500;
    font-size: 110%;
}

.small-test-button {
    font-family: "Helvetica Neue"; 
    width: 100%;
    border-radius: 20px;
    font-size: 120%;
    border: white;
    padding: 5px;
    margin: 0px 0px 10px 0px;
    cursor: pointer;
    font-weight: 500;
    background:  #ECEEF3;
}
