.test-results {
  display: flex;
  width: 98%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  border-radius: 5px;
}

.test-score {
  width: 100%;
  background: transparent;
}

.metrics {
  background: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 5px;
  flex-direction: row;
  gap: 10px;
}

.left-hand-side-metrics {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.right-hand-side-metrics {
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}

.right-hand-size-bottom-metrics {
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.metric {
  display: flex;
  flex: 1;
  padding: 10px;  
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 2px 2px 5px rgb(91, 88, 88);
  border: 1px solid #0a7c8bb6;
  border-radius: 5px;
}

.metric-header-label {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: rgb(91, 88, 88);
  width: 100%;
}

.metric-header-mobile {
  display: None;
}
.metrics-mobile {
  display: None;
}

.metric-div {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .test-results {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: none;
    border: none;
    background: transparent;
    border-radius: 5px;
    border-bottom: 1px solid rgb(91, 88, 88);
    box-shadow: 2px 2px 5px rgb(91, 88, 88);
    border-radius: 5px;
  }

  .test-results p {
    font-weight: bold;
    text-decoration: underline;
    font-size: 100%;
    text-align: start;
    color: rgb(91, 88, 88);
  }

  .test-score {
    display: None;
  }
  
  .metric-header-mobile {
    display: flex;
    font-weight: bold;
    color: rgb(91, 88, 88);
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(91, 88, 88);
    border-radius: 5px;
  }

  .metric-header-mobile label {
    align-self: center;
    font-weight: bold;
    color: rgb(91, 88, 88);
    align-self: center;
    width: 200px;
  }
  
  .metric-header-mobile button {
    justify-self: center;
    align-self: center;
    background-color: transparent;
    color: #0a7c8bb6;
    border: transparent;
    font-size: 170%;
    cursor: pointer;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .metrics-mobile {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 90%;
  }
}
