.signal-competence {
  display: flex;
  background-color: #0a7c8bb6;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex-direction: column;
  padding-bottom: 40px;
}

/* #0a7c8bb6 */
/* color: rgb(91, 88, 88); */

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.heading h1 {
  font-size: 50px;
  font-weight: bold;
  color: white;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
}

.info p {
  color: white;
  text-align: center;
  line-height: 2;
  font-size: 16px;
}

.get-started {
  display: flex;
  padding-top: 30px;
}

.get-started button {
  color: #0a7c8bb6;
  border: #0a7c8bb6;
  background-color: none;
  border-radius: 5px;
  width: 150px;
  height: 50px;
  border-color: white;
  font-weight: bold;
  cursor: pointer;
}

.get-started-button:hover {
  color: white;
  background-color: #0a7c8bb6;
  border: none;
}

@media (max-width: 768px) {
  .signal-competence {
    display: flex;
    background-color: #0a7c8bb6;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    flex-direction: column;
    padding-bottom: 40px;
  }

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .heading h1 {
    font-size: 100%;
    font-weight: bold;
    color: white;
  }

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .info p {
    color: white;
    text-align: center;
    line-height: 1.5;
    font-size: 10px;
  }

  .get-started {
    display: flex;
    padding-top: 15px;
  }

  .get-started button {
    color: #0a7c8bb6;
    background-color: none;
    border-radius: 2px;
    font-size: 8px;
    width: 70px;
    height: 25px;
    border-color: white;
    font-weight: bold;
    cursor: pointer;
  }

  .get-started-button:hover {
    color: white;
    background-color: #020af2;
    border: none;
  }
}
