.test-options-root {
    display: flex;
    width: 100%;
    color: black;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 50px;
    padding: 50px 0px 50px 0px;
    background: rgb(249, 249, 255);
}

.test-option {
    display: flex;
    flex-direction: column;
    border: 3px solid rgb(215, 215, 215);
    background: white;
    width: 30%;
    height: 700px;
    border-radius: 50px;
}

.test-option-top {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 30px 0px 20px 40px;
    gap: 25px;
}

.test-option-ibis-logo {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(233, 233, 255);
    border: 2px solid blue;
    color: blue;
    height: 85px;
    width: 95px;
    border-radius: 25px;
    padding: 10px;
}

.test-option-top-details {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.test-option-top-detials-pricing {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
}
.test-details-name {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0px;
    font-weight: 400;
}
.test-price {
    font-size: 35px;
    margin: 0px;
}
.test-option-top-detials-pricing p {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: grey;
    margin: 0px 0px 8px 0px;
}

.test-option-information {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px 0px 0px 40px;
    align-items: flex-start;
    align-self: flex-start;
}
.test-option-information h3 {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 600;
    font-size: 20px;
    margin: 0px;
}

.include-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.include-text-mark {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    border-radius: 100px;
    padding: 0px 5px 0px 5px;
}
.include-text-text {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 5px 0px 5px 0px;
}

.test-option-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    padding: 100px 0px 15px 0px;
}

.test-option-button button {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    display: flex;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 10px;
    gap: 20px;
    border-radius: 25px;
    border-color: blue;
    font-size: 20px;
    cursor: pointer;
}
.test-option-button button:hover {
    font-weight: 500;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}