.checkout-root {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: white;
    transform: scale(0.75);
}

.checkout {
    display: flex;
    background: white;
    overflow-y: auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.checkout-back-icon {
    font-size: 200%;
    color: black;
    cursor: pointer;
    padding: 5px;
}
.checkout-back-icon:hover {
    box-shadow: none;
}

.checkout-form {
    width: 100%;
    max-height: 950px;
}

.payment-complete {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgb(237, 247, 237);
    border: 30px solid white;
}

.payment-alert {
    margin: "auto";
}

.payment-alert {
    width: 50%;
}

.payment-alert div {
    font-size: 120%;
}

.payment-alert svg {
    font-size: 40px;
}

.checkout-top {
    display: flex;
    width: 58%;
    height: 100%;
    background: white;
    justify-content: flex-end;
    align-items: flex-start;
}
.checkout-top button {
    font-weight: 500;
    background: white;
    color: rgb(82, 82, 82);
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 120%;
    border: #b0b0b0;
    cursor: pointer;
}
.checkout-top button:hover {
    color: rgb(29, 29, 29);
}

.checkout-bottom {
    display: flex;
    width: 100%;
    padding: 20px 0px 20px 0px;
    background: white;
    justify-content: center;
    align-items: center;
}
.checkout-bottom button {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background: white;
    color: rgb(82, 82, 82);
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 120%;
    border: #b0b0b0;
    cursor: pointer;
}
.checkout-bottom button:hover {
    border-color: #b0b0b0;
    color: rgb(29, 29, 29);
}

@media (max-width: 768px) {
}