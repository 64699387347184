.pre-test {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pre-test-div {
  display: flex;
  border: 2px solid rgb(186, 185, 185);
  box-shadow: 2px 2px 4px rgb(186, 185, 185);
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
}

.test-portal-information {
  display: flex;
  padding: 10px 10px 20px 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.test-portal-information h1 {
  width: 80%;
  font-size: 150%;
  margin-top: 0px;
}

.pre-test h1 {
  color: rgb(91, 88, 88);
  text-shadow: 2px 2px 4px rgba(150, 136, 136, 0.5);
}

.pre-test-close-div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 20px;
}

.pre-test-close-div button {
  background-color: white;
  border: white;
  cursor: pointer;
  border-radius: 20px;
  color: rgb(91, 88, 88);
}

.rules {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

.text-p-styling {
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.text-h3-styling {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  text-align: start;
  font-size: 150%;
  width: 90%;
}

.pre-test-start {
  padding-top: 50px;
}

.pre-test-start button {
  display: flex;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  color: #0a7c8bb6;
  margin: 10px;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #0a7c8bb6;
  background-color: rgb(243, 242, 242);
  box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.pre-test-start button:hover {
  background: white;
  color: #0a7c8bb6;
  border-color: #0a7c8bb6;
}

@media (max-width: 768px) {
  .pre-test {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .pre-test-div {
    display: flex;
    border: 2px solid #0a7c8bb6;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 80%;
    flex-direction: column;
    align-items: center;
  }

  .test-portal-information {
    display: flex;
    padding: 0px 10px 10px 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .test-portal-information h1 {
    width: 90%;
    font-size: 100%;
  }

  .no-tests-available {
    display: flex;
    width: 70%;
    height: 100%;
    color: rgb(91, 88, 88);
    text-shadow: 2px 2px 4px rgba(150, 136, 136, 0.5);
    font-size: 30px;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    font-size: 100%;
  }

  .pre-test h1 {
    color: rgb(91, 88, 88);
    text-shadow: 2px 2px 4px rgba(150, 136, 136, 0.5);
  }

  .pre-test-close-div {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 10px 10px 0px 10px;
  }

  .booking-portal-close-icon {
    font-size: 100%;
  }

  .rules {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    font-weight: bold;
    font-size: 70%;
    color: black;
  }

  .pre-test-start {
    padding-top: 10px;
  }

  .pre-test-start button {
    border-radius: 5px;
    cursor: pointer;
    background: #0a7c8bb6;
    color: white;
    font-weight: bold;
    width: 150px;
    height: 30px;
    border: white;
    font-size: 70%;
  }

  .pre-test-start button:hover {
    background: white;
    color: #020af2;
  }

}