.register-user {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.register-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.register-component {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.register-heading {
  display: flex;
  padding-bottom: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.register-heading h3 {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  padding: 10px;
  font-weight: 400;
  margin: 0;
  font-size: 150%;
  justify-self: center;
}

.register-inputs {
  padding-left: 30px;
}

.register-inputs div {
  position: relative;
  /* Add this line */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
}

.register-inputs div label {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  display: flex;
  flex-direction: row;
  font-size: 100%;
}

.register-inputs div input {
  border: 0.5px solid black;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  width: 300px;
}

.username-taken {
  color: 'red';
  font-size: 80%;
  margin: 0px;
  text-decoration: underline;
}

.register-inputs div select {
  width: 200px;
}

.country-dropdown {
  top: 100%;
  background: white;
  border: 1px solid gray;
  width: 300px;
  max-height: 100px;
  overflow-y: auto;
  padding-left: 0px;
  list-style: none;
  margin: 0px;
  border-radius: 5px;
}

.country-dropdown li {
  display: flex;
  align-items: flex-start;
  padding: 8px;
  font-size: 70%;
  cursor: pointer;
  margin: 0;
  border-radius: 3px;
}

.country-dropdown li:hover {
  background-color: #f0f0f0;
}

.consents {
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 20px 0px 0px 0px
}

.consents div {
  display: flex;
  padding: 0px 0px 0px 35px;
}

.consents-label {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  padding-left: 10px;
}

.star {
  color: red;
}

.register-button-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0px 10px 30px
}

.register-button-div button {
  display: flex;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  color: #0a7c8bb6;
  margin: 10px;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #0a7c8bb6;
  background-color: rgb(243, 242, 242);
  box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.register-button-div button:hover {
  background: white;
  color: #0a7c8bb6;
  border: 2px solid #0a7c8bb6;
  text-decoration: underline;
}

.register-warning {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 0px 0px 0px 35px;
}

.register-warning label {
  color: red;
  text-align: start;
}

.register-logout-div {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-end;
}

.register-logout {
  color: #0a7c8bb6;
  font-size: 300%;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}

.register-logout:hover {
  color: white;
  background: #0a7c8bb6;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}