.dashboard-test-history-root {
    display: flex;
    background: #F6F7F9;
    width: 100%;
    flex-direction: column;
    padding-bottom: 80px;
    padding-right: 20px;
}

.dashboard-test-history-table {
    display: flex;
    border: 2px solid #ECEEF3;
    border-radius: 10px;
    margin-left: 35px;
    background: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashboard-test-history-top {
    display: flex;
    padding: 20px 0px 5px 35px;
    width: 100%;
}
.dashboard-test-history-top h1 {
    font-weight: 400;
    font-size: 130%;
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Helvetica Neue";
}

.dashboard-test-history-heading {
    display: flex;
    flex-direction: row;
    width: 99%;
    background:#F6F7F9;
    margin-top: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.dashboard-test-history-heading p {
    font-family: "Helvetica Neue";
    font-weight: 500;
    font-size: 80%;
    flex: 1;
}

.dashboard-text-history-items {
    display: flex;
    margin-top: 5px;
    margin-bottom: 0px;
    width: 99%;
    /* max-height: 150px; */
    /* overflow-y: auto; */
    flex-direction: column;
}

.dashboard-text-history-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.dashboard-text-history-label {
    display: flex;
    flex: 1;
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-size: 80%;
    margin: 10px 0px 10px 0px;
    justify-content: center;
}

.processing-results-label {
    display: flex;
    flex: 1;
    font-family: "Helvetica Neue";
    font-weight: 500;
    font-size: 100%;
    margin: 7px 0px 7px 0px;
    justify-content: center;
}

.test-history-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: "Helvetica Neue";
    font-weight: 400;
    border-radius: 20px;
    height: 30px;
    width: 150px;
    color: #0412F5;
    cursor: pointer;
    border: 1px solid #0412F5;
    background-color: #F6F7F9;
}

.test-history-button:hover {
    text-decoration: underline;
}