.page-left-root {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.page-left-root p {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0px;
    font-size: 130%;
    font-weight: 400;
    width: 90%;
}

.page-left-root button {
    display: flex;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    color: #0a7c8bb6;
    margin: 10px;
    border-radius: 5px;
    height: 40px;
    width: 300px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #0a7c8bb6;
    background-color: rgb(243, 242, 242);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
}
.page-left-root button:hover {
    background: white;
    color: #0a7c8bb6;
    border-color: #0a7c8bb6;
  }