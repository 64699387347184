.small-dashboard-navigation-root {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px 0px 10px 0px;
    background: #F6F7F9;
}

.navigation-performance-title {
    display: flex;
    padding: 0px 30px 0px 35px;
}
.navigation-performance-title h1 {
    font-weight: 400;
    font-size: 130%;
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 5px;
    font-family: "Helvetica Neue";
}

.small-dashboard-navigation-steps {
    display: flex;
    padding: 0px 0px 0px 30px;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
    gap: 20px;
    justify-content: flex-start;  
}
