.stats-root {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 40px;
    gap: 20px;
}

.small-stat-green {
    display: flex;
    width: 250px;
    height: 150px;
    padding: 10px;
    background: rgb(226, 249, 226);
    border-radius: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.small-stat-green svg {
    color: white;
    background: rgb(34, 145, 34);
    border-radius: 50px;
    font-size: 50px;
    padding: 5px;
}
.small-stat-green p {
    margin-top: 15px;
    margin-left: 5px;
    margin-bottom: 5px;
}
.small-stat-green h3 {
    margin-left: 5px;
}

.small-stat-blue {
    display: flex;
    width: 250px;
    height: 150px;
    padding: 10px;
    border-radius: 10px;
    background: rgb(226, 240, 255);
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.small-stat-blue svg {
    color: white;
    background: rgb(51, 115, 180);
    border-radius: 50px;
    font-size: 50px;
    padding: 5px;
}
.small-stat-blue p {
    margin-top: 15px;
    margin-left: 5px;
    margin-bottom: 5px;
}
.small-stat-blue h3 {
    margin-left: 5px;
}