.post-test {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.post-test-div {
  border: 2px solid rgb(186, 185, 185);
  box-shadow: 2px 2px 4px rgb(186, 185, 185);
  display: flex;
  width: 40%;
  flex-direction: column;
  border-radius: 10px;
}

.post-test-div h3 {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  padding: 10px;
  color: rgb(91, 88, 88);
}

.post-test-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.post-test-button button {
  display: flex;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  color: #0a7c8bb6;
  margin: 10px;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #0a7c8bb6;
  background-color: rgb(243, 242, 242);
  box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

@media (max-width: 768px) {
  .post-test {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .post-test-div {
    border: 2px solid #0a7c8bb6;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    display: flex;
    width: 60%;
    flex-direction: column;
    border-radius: 5px;
  }

  .post-test-div h3 {
    padding: 10px;
    color: rgb(91, 88, 88);
    font-size: 70%;
  }

  .post-test-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
  }

  .post-test-button button {
    border-radius: 5px;
    cursor: pointer;
    background: #0a7c8bb6;
    color: white;
    font-weight: bold;
    width: 100px;
    height: 30px;
    border: white;
    font-size: 70%;
  }
}