.test-session {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.test-session-div {
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.test-session-timer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 10%;
}

.test-session-icon {
  font-size: 60px;
  color: black;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
}

.test-session-tests-status {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  margin-right: 20px;
}

.tests-status {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  right: 0;
  z-index: 1000;
  border: 2px solid #0a7c8bb6;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  width: 300px;
  height: 300px;
  gap: 2px; /* Adds space between squares */
  background: white;
}

.tests-status-grid {
  display: flex;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 4 columns */
  grid-template-rows: repeat(5, 1fr);   /* 3 rows */
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 4px;
  gap: 4px;
}

.reset-test-question-div {
  display: flex;
  width: 80%;
  padding: 40px 0px 0px 35px;
  justify-content: flex-start;
  align-items: flex-start;
}

.reset-test-question-div label {
  cursor: pointer;
  font-weight: bold;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 15px;
  color: #036976b6;
  text-decoration: underline;
  text-decoration-color: #02535db6;
}

.test-square {
  display: flex;
  background-color: white;
  border: 2px solid #333;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.pagination-buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
}

.pagination-buttons button {
  padding: 5px 10px;
  background-color: white;
  color: #036976b6;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.test-session-timer p {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 30px;
  font-weight: 500;
  margin: 0px;
  padding: 10px;
}

.test-session-question {
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 15%;
}

.test-session-question p {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  user-select: none;
  font-size: 120%;
  font-weight: 500;
  color: rgb(91, 88, 88);
  text-shadow: 1px 1px 1px rgba(150, 136, 136, 0.5);
}

.test-session-answers {
  padding-top: 30px;
  display: flex;
  width: 80%;
  margin-left: 40px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 35px;
  flex-direction: column;
  font-size: 100%;
}

.test-session-answers label {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
  margin: 0px;
}

.test-session-answers label input {
  align-self: center;
  margin: 0px 10px 0px 10px
}

.test-session-bottom-navigation {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
  padding: 40px 100px 40px 100px;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: 20%;
}

.test-session-bottom-navigation button {
  display: flex;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  color: #0a7c8bb6;
  margin: 10px;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #0a7c8bb6;
  background-color: rgb(243, 242, 242);
  box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.test-session-bottom-navigation button:hover {
  color: #0a7c8bb6;
  background: white;
}

.end-session {
  display: flex;
  border: 2px solid rgb(186, 185, 185);
  box-shadow: 2px 2px 4px rgb(186, 185, 185);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.end-session h2 {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  padding: 10px;
  color: rgb(91, 88, 88);
  text-shadow: 1px 1px 2px rgba(150, 136, 136, 0.5);
  width: 90%;
  font-weight: 400;
  font-size: 130%;
  text-align: center;
}

.end-session-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
  justify-content: center;
}

.end-session-buttons button {
  display: flex;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  color: #0a7c8bb6;
  margin: 10px;
  border-radius: 5px;
  height: 40px;
  width: 100px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #0a7c8bb6;
  background-color: rgb(243, 242, 242);
  box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.end-session-buttons button:hover {
  background: white;
  color: #0a7c8bb6;
  border-color: #0a7c8bb6;
}

@media (max-width: 768px) {
  .test-session {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .unavailable-test-session {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .unavailable-test-session h1 {
    padding: 20px;
    font-size: 100%;
    width: 80%;
    color: rgb(91, 88, 88);
    text-shadow: 2px 2px 4px rgba(150, 136, 136, 0.5);
  }

  .unavailable-test-session button {
    border-radius: 5px;
    cursor: pointer;
    background: #0a7c8bb6;
    color: white;
    font-weight: bold;
    width: 30%;
    height: 4%;
    border: white;
    font-size: 100%;
  }
  
  .test-session-div {
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  
  .test-session-timer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    height: 50px;
  }
    
  .test-session-timer p {
    font-size: 70%;
    font-weight: bold;
    margin: 0px;
    padding: 10px;
  }
  
  .test-session-icon {
    font-size: 40px;
    color: black;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
  }
  
  .test-session-tests-status {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    margin-right: 5px;
  }
  
  .tests-status {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    right: 0;
    z-index: 1000;
    border: 1px solid #0a7c8bb6;
    border-radius: 2px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    width: 150px;
    height: 150px;
    gap: 2px; /* Adds space between squares */
    background: white;
  }
  
  .tests-status-grid {
    display: flex;
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 4 columns */
    grid-template-rows: repeat(5, 1fr);   /* 3 rows */
    width: 100%;
    height: 100%;
    flex: 1;
    padding: 1px;
    gap: 1px;
  }
  
  .reset-test-question-div {
    align-self: flex-start;
    display: flex;
    width: 70%;
    padding: 20px 5px 5px 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .reset-test-question-div label {
    cursor: pointer;
    font-weight: bold;
    font-size: 50%;
    color: #036976b6;
    text-decoration: underline;
    text-decoration-color: #02535db6;
  }
  
  .test-square {
    display: flex;
    background-color: white;
    border: 1px solid #333;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 10%;
  }
  
  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    padding: 1px 1px 1px 1px;
  }
  
  .pagination-buttons button {
    padding: 5px 10px;
    background-color: white;
    color: #036976b6;
    font-weight: bold;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-size: 20%;
  }
  
  .test-session-question {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 100px;
  }
  
  .test-session-question p {
    font-size: 70%;
    font-weight: bold;
    color: rgb(91, 88, 88);
    text-shadow: 1px 1px 2px rgba(150, 136, 136, 0.5);
  }
  
  .test-session-answers {
    padding-top: 10px;
    display: flex;
    width: 90%;
    margin-left: 15px;
    align-self: flex-start;
    gap: 20px;
    flex-direction: column;
    font-size: 70%;
    height: 300px;
  }

  .test-session-answers label {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .test-session-answers label input {
    height: 10px;
    width: 10px;
    margin: 0px;
    align-self: center;
  }
  
  .test-session-bottom-navigation {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 25px;
    padding: 8px 20px 8px 20px;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    height: 100px;
  }
  
  .test-session-bottom-navigation button {
    border-radius: 5px;
    cursor: pointer;
    background: #0a7c8bb6;
    border-color: #0a7c8bb6;
    color: white;
    font-weight: bold;
    width: 100px;
    height: 30px;
    font-size: 50%;
    border: white;
  }
  
  .end-session {
    display: flex;
    flex-direction: column;
    border: 2px solid #0a7c8bb6;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  
  .end-session h2 {
    padding: 10px;
    color: rgb(91, 88, 88);
    text-shadow: 1px 1px 2px rgba(150, 136, 136, 0.5);
    width: 90%;
    text-align: center;
    font-size: 70%;
  }
  
  .end-session-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;
    justify-content: center;
  }
  
  .end-session-buttons button {
    background: #0a7c8bb6;
    color: white;
    border: white;
    border-radius: 5px;
    font-size: 15px;
    width: 80px;
    height: 30px;
    cursor: pointer;
  }
}
