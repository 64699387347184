.dashboard-heading-root {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: white;
    border-bottom: 2px solid #ECEEF3;
    padding: 10px 30px 10px 30px;
    height: 60px;
}

.search-bar {
    align-self: center;
    background: url('icons/search-icon.png') no-repeat left center;
    background-size: 20px 20px;
    background-position: 8px 8px;
    padding-left: 33px; 
    justify-self: center;
    font-family: "Helvetica Neue";
    color: #5E5E5E;
    font-size: 80%;
    font-weight: 500;
    width: 300px;
    height: 35px;
    border-radius: 10px;
    border: 1px solid #ECEEF3;
    background-color: white;
    outline: none;
}

.dashboard-heading-rhs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.begin-test-button {
    font-family: "Helvetica Neue";
    font-weight: 400;
    color: white;
    width: 250px;
    font-size: 100%;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #0412F5;
    border: white;
}

.dashboard-heading-rhs-separator {
   background:  #ECEEF3;
   height: 90%;
   width: 1px;
}

.dashboard-heading-user {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.dashboard-heading-user-user-icon {
    height: 35px;
    width: 35px;
    background: #ECEEF3;
    border-radius: 100%;
}

.dashboard-heading-user-user-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.dashboard-heading-user-user-text-username {
    margin: 0px;
    font-size: 80%;
    font-weight: 500;
    font-family: "Helvetica Neue";
}
.dashboard-heading-user-user-text-business {
    margin: 0px;
    font-size: 70%;
    font-weight: 500;
    font-family: "Helvetica Neue";
    color: #5E5E5E;
}

