.unavailable-page {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.unavailable-page h1 {
    padding: 20px;
    font-size: 150%;
    width: 60%;
    color: rgb(91, 88, 88);
    text-shadow: 2px 2px 4px rgba(150, 136, 136, 0.5);
}

.unavailable-page button {
    border-radius: 5px;
    cursor: pointer;
    background: #0a7c8bb6;
    color: white;
    font-weight: bold;
    width: 10%;
    height: 5%;
    border: white;
    font-size: 150%;
}

@media (max-width: 768px) {
    .unavailable-page {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .unavailable-page h1 {
        padding: 20px;
        font-size: 100%;
        width: 90%;
        color: rgb(91, 88, 88);
        text-shadow: 2px 2px 4px rgba(150, 136, 136, 0.5);
    }
    
    .unavailable-page button {
        border-radius: 5px;
        cursor: pointer;
        background: #0a7c8bb6;
        color: white;
        font-weight: bold;
        width: 120px;
        height: 30px;
        font-size: 80%;
        border: white;
    }
}

