.practice-test-graphs-root {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-right: 40px;
}

.practice-test-graphs {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 0px 30px 40px;
}

.practice-test-graph-label {
    font-size: 60%;
    font-weight: 400;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: grey;
}

.practice-test-score-graph {
    width: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 10px;
    border-radius: 10px;
}
.practice-test-time-graph {
    width: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 10px;
    border-radius: 10px;
}
.practice-test-section-graph {
    width: 890px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 10px;
    border-radius: 10px;
}