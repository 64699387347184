.purchase-form-root {
    display: flex;
    background: white;
    width: 100%;
    flex-direction: column;
    padding-bottom: 20px;
    padding-right: 20px;
}

.purchase-form-order-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid rgb(186, 185, 185);
    border-radius: 10px;
    width: 80%;
    margin-left: 40px;
    overflow-y: auto;
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    padding: 10px 0px 0px 0px;
    margin-bottom: 20px;
}

.purchase-form-header {
    display: flex;
    margin: 0px 20px 0px 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(186, 185, 185);
    justify-content: space-between;
    align-items: center;
}

.information-icon {
    margin-right: 10px;
    cursor: pointer;
}

.purchase-form-order-info {
    display: flex;
    margin-left: 30px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.order-info-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100%;
    font-weight: 400;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    width: 80px;
    height: 60px;
    color: white;
    background: #0a7c8bb6;
    border: 1px solid black;
    border-radius: 5px;
    margin: 0px;
}

.order-info-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.order-info-header {
    font-size: 80%;
    font-weight: 500;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0px;
}

.order-info-test-amount {
    display: flex;
    padding-right: 20px;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

.order-info-test-amount-button {
    display: flex;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    cursor: pointer;
    border: 1px solid rgb(186, 185, 185);
    border-radius: 5px;
    background-color: white;
    size: 40px;
}

.order-info-test-amount-button:hover {
    border: 1px solid #0a7c8bb6;
}

.order-total {
    display: flex;
    gap: 10px;
    flex: 1;
    background: rgb(243, 242, 242);
    margin: 10px 5px 5px 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 0px 10px 20px;
}

.purchase-form-order-description {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.purchase-form-buttons {
    display: flex;
    margin-left: 40px;
    gap: 20px;
}

.purchase-form-buttons button {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    color: #0a7c8bb6;
    margin: 0px 10px 10px 0px;
    border-radius: 5px;
    height: 40px;
    width: 300px;
    cursor: pointer;
    border: 1px solid #0a7c8bb6;
    background-color: rgb(243, 242, 242);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
}

.purchase-form-buttons button:hover {
    border: 2px solid #0a7c8bb6;
    text-decoration: underline;
}