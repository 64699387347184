.practice-test-root {
    display: flex;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    border-radius: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 30px;
}

.test-taken-div {
    display: flex;
    flex-direction: column;
    width: 100%;
}