.profile-root {
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  border-radius: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.profile {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-div {
  display: flex;
  position: relative;
  flex-direction: column;
  border: 2px solid #0a7c8bb6;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.profile-heading {
  display: flex;
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  align-items: center;
  justify-content: space-between;
  background: #0a7c8bb6 ;
  padding: 20px;
  gap: 10px;
}

.profile-close-icon {
  color: white;
  cursor: pointer;
  align-self: flex-start;
}

.profile-heading h1 {
  margin: 0px;
  color: white;
  text-shadow: 1px 1px 2px black;
}

.profile-details {
    display: flex;
    flex-direction: column;
    padding: 20px 10px 10px 10px;
}

@media (max-width: 768px) {
  .profile-root {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .profile-div {
    width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    border: 2px solid #0a7c8bb6;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }
  
  .profile-heading {
    display: flex;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    align-items: center;
    justify-content: space-between;
    background: #0a7c8bb6 ;
    padding: 7px;
    gap: 5px;
  }
  
  .profile-close-icon {
    color: white;
    cursor: pointer;
    align-self: flex-start;
    font-size: 70%;
  }
  
  .profile-heading h1 {
    margin: 0px;
    font-size: 100%;
    color: white;
    text-shadow: 1px 1px 2px black;
  }
  
  .profile-details {
      display: flex;
      flex-direction: column;
      padding: 10px 5px 5px 5px;
  }
}
