.onboarding {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  background-color: #ffffff;
  padding-top: 5%;
  padding-bottom: 5%;
}

.onboarding-info {
  flex: 1;
  padding: 2%;
  margin-left: 2%;
}

.onboarding-info h1 {
  font-size: 250%;
  margin-top: 5px;
  color: rgb(91, 88, 88);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.onboarding-info p {
  font-size: 200%;
  color: rgb(91, 88, 88);
}

.take-test-button {
  margin-top: 7%;
  margin-right: 1%;
  padding: 1% 2%;
  border: none;
  cursor: pointer;
  background-color: #0a7c8bb6;
  color: #ffffff;
  width: 150px;
  height: 50px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 100%; /* Adjust font size */
  transition: background-color 0.3s ease;
}

.take-test-button:hover {
  background-color: white;
  color: black;
}

.onboarding-info img{
  width: 100%;
}

.certificate {
  padding: 20px;
  margin-top: 7%;
  flex-direction: column;
  flex: 1;
  margin-left: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.certificate img {
  flex: 1;
  display: flex;
  width: 62%;
}

@media (max-width: 768px) {
  .onboarding {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    background-color: #ffffff;
    padding-top: 15%;
    padding-bottom: 5%;
    flex-direction: column;
  }
  
  .onboarding-info {
    flex: 1;
    padding: 2%;
    margin-left: 2%;
  }
  
  .onboarding-info h1 {
    font-size: 150%;
    margin-bottom: 3%;
    margin-top: 5%;
  }
  
  .onboarding-info .highlight {
    color: #020af2;
  }
  
  .onboarding-info p {
    font-size: 100%;
  }
  
  .onboarding-info .take-test-button {
    margin-top: 7%;
    margin-right: 1%;
    padding: 1% 2%;
    border: none;
    cursor: pointer;
    background-color: #0a7c8bb6;
    color: #ffffff;
    width: 70px;
    height: 25px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 8px;
    transition: background-color 0.3s ease;
  }
  
  .take-test-button:hover {
    background-color: white;
    color: black;
  }
  
  .onboarding-info img{
    width: 100%;
  }
  
  .certificate {
    margin: 0px;
    padding: 10px;
    flex-direction: column;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .certificate img {
    flex: 1;
    display: flex;
    width: 62%;
  }
}

