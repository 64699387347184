.test-guide-root {
    display: grid;
    border: 2px solid #ECEEF3;
    background: white;
    border-radius: 20px;
    /* width: 400px; */
    flex: 0 0 40%;
    padding: 0px 15px 0px 15px;
}

.test-guide-top-div {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 0px 0px 0px;
    justify-content: space-between;
}

.test-guide-top {
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.test-guide-top img {
    align-self: center;
    justify-self: flex-start;
    width: 25px;
    height: 25px;
    border-radius: 2px;
    background: #F6F7F9;
    border: 1px solid #ECEEF3;
    padding: 2px 0px 0px 0px;
}
.test-guide-title {
    margin: 0px 0px 0px 0px;
    font-family: "Helvetica Neue";
    font-size: 130%;
}

.test-guide-information {
    align-self: flex-start;
    font-family: "Helvetica Neue";
    margin: 0px;
    font-size: 70%;
    font-weight: 500;
    color: #ECEEF3;
    border: 2px solid #ECEEF3;
    padding: 0px 7px 0px 7px;
    border-radius: 100%;
}

.test-guides {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 10px;
    align-self: flex-start;
    justify-self: flex-start;
    gap: 5px;
}

.guideline {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.guideline-counter {
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-size: 130%;
    background: rgb(236, 238, 243);
    text-align: center;
    border-radius: 100%;
    padding: 2px 12px 2px 12px;
    margin: 0px;
}

.test-guide-separator {
    background: #ECEEF3;
    height: 2px;
}

.test-guide-disclaimer {
    font-size: 80%;
    font-family: "Helvetica Neue";
    color: #5E5E5E;
}