.enter-pin-root {
    display: flex;
    padding: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.enter-pin-root h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 200%;
    color: rgb(91, 88, 88);
    margin-top: 5px;
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.5);
    margin-bottom: 0px;
    width: 70%;
}

.enter-pin-div {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
}

.enter-pin-div button {
    border-radius: 5px;
    background: #0a7c8bb6;
    border-color: #0a7c8bb6;
    font-size: 100%;
    font-weight: bold;
    color: white;
    height: 40px;
    width: 200px;
    cursor: pointer;
    margin-top: 30px;
}

.enter-pin-div button:hover {
    background: white;
    border-color: #0a7c8bb6;
    color: #0a7c8bb6;
}

.enter-pin-input {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.enter-pin-input p {
    margin: 0px;
    color: rgb(91, 88, 88);
    font-weight: bold;
}

.enter-pin-input input {
    width: 250px;
    border-radius: 5px;
    border: 1px solid rgb(160, 159, 159);
    padding-left: 10px;
    height: 40px;
}

.enter-pin-input input:hover {
    border: 2px solid #0a7c8bb6;
}

.password-div {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.password-div svg {
    font-size: 150%;
}

.password-input {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
}

.password-input p {
    font-size: 60%;
    margin-right: 5px;
    cursor: pointer;
    color: #0b606bb6;
}

.password-input p:hover {
    text-decoration: underline;
}

.reset-error-message {
    color: red;
    text-decoration: underline;
    font-size: 80%;
    text-align: start;
    margin: 0px;
}

@media (max-width: 768px) {
    .enter-pin-root h1 {
        font-family: 'Roboto', sans-serif;
        font-size: 120%;
        color: rgb(91, 88, 88);
        margin-top: 5px;
        text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.5);
        margin-bottom: 0px;
        width: 100%;
    }

    .enter-pin-div {
        gap: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .enter-pin-div button {
        border-radius: 5px;
        background: #0a7c8bb6;
        border-color: #0a7c8bb6;
        font-weight: bold;
        color: white;
        cursor: pointer;
        width: 100px;
        font-size: 60%;
        height: 30px;
    }

    .enter-pin-div button:hover {
        background: white;
        border-color: #0a7c8bb6;
        color: #0a7c8bb6;
    }

    .enter-pin-input {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .enter-pin-input p {
        font-size: 80%;
    }

    .enter-pin-input input {
        height: 30px;
        width: 150px;
        font-size: 60%;
    }

    .password-div svg {
        font-size: 100%;
    }

    .password-input p {
        font-size: 50%;
    }

    .reset-error-message {
        font-size: 60%;
    }
}