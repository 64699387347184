.practice-test-stats {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 0px 0px 0px 40px;
}

.small-stat-yellow {
    display: flex;
    width: 250px;
    height: 150px;
    padding: 10px;
    background: rgb(246, 249, 226);
    border-radius: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.small-stat-yellow svg {
    color: white;
    background: rgb(208, 208, 42);
    border-radius: 50px;
    font-size: 50px;
    padding: 5px;
}
.small-stat-yellow p {
    margin-top: 15px;
    margin-left: 5px;
    margin-bottom: 5px;
}
.small-stat-yellow h3 {
    margin-left: 5px;
}