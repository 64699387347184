.line-chart {
    flex: 1;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .line-char {
        width: 300px;
        height: 300px;
        position: relative;
    }
}