.practice-test-navigation-root {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 10px 10px 40px;
}

.practice-test-navigation-steps {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
}

.practice-test-step {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 450px;
    min-width: 400px;
    border: 1px solid rgb(186, 185, 185);
    box-shadow: 1px 1px 2px rgb(186, 185, 185);
    border-radius: 10px;
}