.error-flag {
  display: flex;
  width: 100%;
  background: red;
  flex-direction: row;
  border-radius: 0px;
}

.error-flag-information {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
}

.error-flag-information p {
  font-size: 15px;
  color: white;
  font-weight: bold;
  margin: 2px;
}

.error-flag-close {
  display: flex;
  width: 10%;
  border-radius: 10px;
  background-color: red;
  justify-content: flex-end;
  align-items: center;
}

.close-icon {
  font-size: 15px;
  cursor: pointer;
  color: white;
  margin-right: 10px;
}
