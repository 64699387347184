.section-barchart {
    width: 780px;
    height: 250px;
    position: relative;
}

@media (max-width: 768px) {
    .section-barchart {
        width: 300px;
        height: 300px;
        position: relative;
    }
}